<template>
</template>

<script>
	export default {
		created() {
			this.$router.push({
				path: "/"
			});
		},
	}
</script>

<style>
</style>
